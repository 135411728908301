// @flow

import firebase from '@firebase/app'
import '@firebase/auth'
import '@firebase/database'
import '@firebase/firestore'

import { firebaseConfig as config } from '../config'

const firebaseConfig = {
  ...config,
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  // debug: true,
  // enableLogging: true,
  userProfile: 'users',
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
  enableClaims: true
}

firebase.initializeApp(firebaseConfig)
// firebase.analytics()
firebase.firestore().settings({ experimentalForceLongPolling: true })
firebase.firestore().enablePersistence({ synchronizeTabs: true })
// firebase.auth().signInAnonymously()
window.firebase = firebase

export default firebase
