import React, { useEffect, useState, memo } from 'react'
import { Image } from 'react-konva'

import fetchImage from '../api/fetchImage'

const JwtKonvaImage = ({
  url,
  accessToken,
  width = 480,
  height = 300,
  rotate,
  offsetX = 0,
  offsetY = 0
}) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    async function getImage () {
      if (accessToken) {
        const image = await fetchImage({ accessToken, url })
        setImage(image)
      }
    }
    getImage()
    return []
  }, [setImage, accessToken, url])

  if (!image || !accessToken) {
    return <Image />
  }

  const imageUrl = URL.createObjectURL(image)
  const imageObject = new window.Image(width, height)
  imageObject.src = imageUrl

  return (
    <Image
      image={imageObject}
      rotation={rotate}
      offsetX={offsetX}
      offsetY={offsetY}
    />
  )
}

export default memo(JwtKonvaImage)
