import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Crosshair, LineSeries, XAxis, XYPlot } from 'react-vis'
import { useTheme } from '@material-ui/core'

import { selectedColor } from '../utils'
import { addSelectedNode } from '../redux/assessmentSlice'

const genData = (scale, column, row, array, normalize) => {
  const result = array.filter(n => n.nx === row && n.ny === column)
  if (!result.length) {
    return []
  }
  const ppg = result.map(n => n.ppg)[0]
  const normalization = normalize ? Math.max(...ppg) : 1
  return ppg.map((k, i) => {
    return { x: i, y: normalize ? k / normalization - scale / 200 : k - scale }
  })
}

const PPGContainer = props => {
  const { selectedNodes, ppg } = useSelector(state => state.assessment)
  const { normalize } = useSelector(state => state.controls)
  const dispatch = useDispatch()
  const [point, setPoint] = useState([])
  const nodeArrayMaxX = Math.max(...ppg.map(n => n.nx))
  const nodeArrayMaxY = Math.max(...ppg.map(n => n.ny))

  const theme = useTheme()
  const { selectedRow, checked } = props

  if (ppg.length === 0) {
    return null
  }
  return (
    <div>
      <XYPlot width={window.innerWidth - 500} height={window.innerHeight - 100}>
        <XAxis />
        {[...Array(checked.checked ? nodeArrayMaxY : nodeArrayMaxX).keys()].map(
          i => {
            const activeSelection = selectedNodes.filter(
              checked.checked
                ? n => n.x === selectedRow && n.y === i
                : n => n.x === i && n.y === selectedRow
            )
            const nodeInfo = checked.checked
              ? {
                  x: selectedRow,
                  y: i,
                  nodeClass: activeSelection.length
                    ? activeSelection[0].nodeClass
                    : 'clear'
                }
              : {
                  x: i,
                  y: selectedRow,
                  nodeClass: activeSelection.length
                    ? activeSelection[0].nodeClass
                    : 'clear'
                }

            return (
              <LineSeries
                key={`ppg ${i}`}
                className={'series' + i}
                data={
                  checked.checked
                    ? genData(i * 400, i, selectedRow, ppg, normalize)
                    : genData(i * 400, selectedRow, i, ppg, normalize)
                }
                color={
                  checked.checked
                    ? selectedColor(
                        selectedRow,
                        i,
                        nodeInfo.nodeClass,
                        selectedNodes,
                        theme
                      )
                    : selectedColor(
                        i,
                        selectedRow,
                        nodeInfo.nodeClass,
                        selectedNodes,
                        theme
                      )
                }
                strokeWidth={4}
                onSeriesClick={() => dispatch(addSelectedNode(nodeInfo))}
                onNearestX={v => setPoint([v])}
              />
            )
          }
        )}
        <Crosshair values={point}>
          {/* Divs inside Crosshair Component required to prevent value box render */}
          <div />
        </Crosshair>
      </XYPlot>
    </div>
  )
}

export default PPGContainer
