import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  truncate: false,
  normalize: false,
  nodeLocations: false,
  classificationView: false,
  waveform: 'HAC',
  opacity: 100
}

const controlSlice = createSlice({
  name: 'controls',
  initialState,
  reducers: {
    toggleTruncate: state => {
      state.truncate = !state.truncate
    },
    toggleNormalize: state => {
      state.normalize = !state.normalize
    },
    toggleNodeLocations: state => {
      state.nodeLocations = !state.nodeLocations
    },
    toggleClassification: state => {
      state.classificationView = !state.classificationView
    },
    setOpacity: (state, action) => {
      state.opacity = action.payload
    },
    setWaveform: (state, action) => {
      state.waveform = action.payload
    }
  }
})

export const {
  toggleNormalize,
  toggleNodeLocations,
  toggleClassification,
  setOpacity,
  setWaveform
} = controlSlice.actions

export default controlSlice.reducer
