import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClinicalContainer from './ClincalContainer'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  selectorColumn: {
    minWidth: '480px',
    width: '482px'
  },

  borderedComponent: {
    border: `2px solid ${theme.palette.secondary.main}`,
    margin: '-2px'
  }
}))

const ViewerContainer = props => {
  const classes = useStyles()
  const { subject } = props
  return (
    <Grid container direction='row' alignItems='stretch'>
      <Grid className={classes.selectorColumn} item xs={3}>
        <Grid
          direction='column'
          justify='flex-start'
          alignItems='stretch'
          container
        >
          <Grid item xs={4}>
            <Typography variant='h2'>{subject}</Typography>
          </Grid>
          <Grid item xs={12}>
            <div />
          </Grid>
          <Grid item xs={12}>
            <div />
          </Grid>
          <Grid item xs={12}>
            <div />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.borderedComponent}>
        <ClinicalContainer subject={subject} />
      </Grid>
    </Grid>
  )
}

export default ViewerContainer
