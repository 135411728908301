import { AppBar, Toolbar } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const Footer = props => {
  return (
    <AppBar position='static' color='primary'>
      <Container maxWidth='md'>
        <Toolbar>
          <Typography variant='body1' color='inherit'>
            &copy; 2021 JVP Labs
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Footer
