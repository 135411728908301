import { Layer, Line } from 'react-konva'
import React from 'react'

const LineDrawComponent = props => {
  const { lines, getLineColor, getWidth, dash } = props
  if (!lines || !lines.length > 0) {
    return null
  }
  return (
    <Layer>
      {lines.map((line, i) => (
        <Line
          key={i}
          points={line.points}
          stroke={getLineColor(line)}
          strokeWidth={getWidth(line)}
          tension={0.5}
          dash={dash}
          lineCap='round'
          globalCompositeOperation='source-over'
          perfectDrawEnabled={false}
        />
      ))}
    </Layer>
  )
}

export default LineDrawComponent
