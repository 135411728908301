import { createSlice } from '@reduxjs/toolkit'

const initialState = { currentCard: 0, subject: null }
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    nextCard: state => {
      state.currentCard += 1
    },
    previousCard: state => {
      state.currentCard -= 1
    },
    initialize: (state, action) => {
      return {
        ...initialState,
        subject: action.payload
      }
    },
    setCard: (state, action) => {
      state.currentCard = action.payload
    }
  }
})

export const {
  nextCard,
  previousCard,
  initialize,
  setCard
} = dashboardSlice.actions

export default dashboardSlice.reducer
