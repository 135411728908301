import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Slider,
  TextField
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}))

const WaveformControls = props => {
  const {
    nodeLocations,
    classificationView,
    opacity,
    toggleNodes,
    setOpacity,
    toggleClassification,
    algorithms,
    waveform,
    setWaveform
  } = props
  console.log(algorithms)

  const classes = useStyles()

  const handleChange = event => {
    setWaveform(event.target.value)
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h4'>Controls</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='continuous-slider' gutterBottom>
            Waveform Opacity
          </Typography>
          <Grid container>
            <Grid item>
              <Slider
                style={{ width: 200 }}
                aria-labelledby='discrete-slider'
                valueLabelDisplay='auto'
                step={10}
                marks
                min={0}
                max={100}
                value={opacity}
                onChange={setOpacity}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nodeLocations}
                    onChange={toggleNodes}
                    name='SwapInNodesChecbox'
                    color='primary'
                  />
                }
                label='Show Nodes'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={classificationView}
                    onChange={toggleClassification}
                    name='SwapInNodesChecbox'
                    color='primary'
                  />
                }
                label='Show Classification View'
              />
            </Grid>
            <Grid item>
              <TextField
                id='waveform-selector'
                select
                label='Waveform Diagram'
                value={waveform}
                onChange={handleChange}
              >
                {algorithms.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default WaveformControls
