import { AppBar, Toolbar } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { APPNAME } from '../config'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Link } from 'wouter'
import React from 'react'

const Header = props => {
  return (
    <AppBar position='sticky' color='primary'>
      <Container maxWidth={false}>
        <Toolbar>
          <Grid container justify='space-between'>
            <Grid item>
              <Link to='/'>
                <Button variant='contained'>Home</Button>
              </Link>
            </Grid>
            <Grid item>
              {/* <Typography variant='h6' color='inherit' align='left'> */}
              {/*   Patient Info: John Doe/63/M */}
              {/* </Typography> */}
            </Grid>
            <Grid item>
              <Typography variant='h4' color='inherit' align='center'>
                {APPNAME}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant='contained' color='secondary'>
                Sign Out
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
