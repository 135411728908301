import React from 'react'
import { useSelector } from 'react-redux'
import { Stage, Layer, Circle, Line, Text } from 'react-konva'
import { useTheme } from '@material-ui/core'

import JwtKonvaImage from '../components/JwtKonvaImage'
import { getAccessToken } from '../redux/authSelector'
import { selectedColor } from '../utils'

const NeckDot = props => {
  const { x, y, fill, stroke, onMouseDown, size } = props
  const handleMouseEnter = e => {}
  return (
    <Circle
      x={x}
      y={y}
      width={size}
      height={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={2}
      onMouseDown={onMouseDown}
      onMouseEnter={handleMouseEnter}
    />
  )
}

const MeasureContainer = props => {
  const theme = useTheme()
  const {
    neckUrl = 'https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ref_frame587997.jpg',
    width = 480,
    height = 300,
    measuredHeight,
    setMeasuredHeight,
    angle
  } = props

  const { ppg, selectedNodes } = useSelector(state => state.assessment)
  const accessToken = useSelector(getAccessToken)

  const lineRoot = { endX: 120, endY: 169, startX: 342, startY: 70, length: 12 }

  const MeasuredLine = (rootLine, measure, length, angle, type = 'static') => {
    const newY =
      rootLine.startY +
      (Math.max(rootLine.startY, rootLine.endY) -
        Math.min(rootLine.startY, rootLine.endY)) *
        measure
    const newX =
      rootLine.startX -
      (Math.max(rootLine.startX, rootLine.endX) -
        Math.min(rootLine.startX, rootLine.endX)) *
        measure

    return spacedLine(
      rootLine,
      newX,
      newY,
      length,
      angle,
      type === 'static'
        ? theme.palette.selectors.fourth
        : theme.palette.selectors.first
    )
  }

  const spacedLine = (rootLine, x, y, length, angle, stroke) => {
    const SUBJECT_SLANT_CORRECTION = 15
    const ratio = Math.tan(
      (Math.PI * (-langle + SUBJECT_SLANT_CORRECTION)) / 180
    )

    const points = [
      x - length * ratio,
      y - length,
      x + length * ratio,
      y + length
    ]

    return (
      <Line strokeWidth={5} stroke={stroke} points={points} lineCap='round' />
    )
  }
  const [langle, length] = [60, 30]
  return (
    <Stage width={480} height={300}>
      <Layer>
        <JwtKonvaImage
          url={neckUrl}
          accessToken={accessToken}
          width={width}
          height={height}
        />
        {measuredHeight ? (
          <Text
            text={measuredHeight}
            fontSize={30}
            fontFamily='Calibri'
            fill={theme.palette.selectors.first}
          />
        ) : null}
        {MeasuredLine(lineRoot, 0, length, langle)}
        {MeasuredLine(lineRoot, 0.25, length, langle)}
        {MeasuredLine(lineRoot, 0.5, length, langle)}
        {MeasuredLine(lineRoot, 0.75, length, langle)}

        {ppg
          .filter(
            cir =>
              selectedNodes.filter(
                node =>
                  node.x === cir.nx &&
                  node.y === cir.ny &&
                  node.nodeClass === 'Biphasic'
              ).length
          )
          .map(cir => (
            <NeckDot
              key={`node ${cir.nx} ${cir.ny} `}
              x={cir.x}
              y={cir.y}
              row={cir.nx}
              column={cir.ny}
              stroke={selectedColor(
                cir.nx,
                cir.ny,
                'Biphasic',
                selectedNodes,
                theme
              )}
              fill={theme.palette.accent.main}
              size={6}
            />
          ))}
        {ppg.map(cir => (
          <NeckDot
            key={`CLICK ${cir.nx} ${cir.ny} `}
            x={cir.x}
            y={cir.y}
            row={cir.nx}
            column={cir.ny}
            fill={null}
            stroke={null}
            size={10}
          />
        ))}

        <Line
          strokeWidth={60}
          stroke={theme.palette.selectors.fourth}
          opacity={0}
          points={[
            lineRoot.startX,
            lineRoot.startY,
            lineRoot.endX,
            lineRoot.endY
          ]}
          onMouseDown={e =>
            setMeasuredHeight(
              (
                (lineRoot.length * (lineRoot.startX - e.evt.layerX)) /
                (lineRoot.startX - lineRoot.endX)
              ).toFixed(1)
            )
          }
        />

        {measuredHeight
          ? MeasuredLine(
              lineRoot,
              measuredHeight / lineRoot.length,
              length,
              angle,
              'measure'
            )
          : null}
      </Layer>
    </Stage>
  )
}

export default MeasureContainer
