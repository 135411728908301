import { createSlice } from '@reduxjs/toolkit'
import pointInPolygon from 'point-in-polygon'
import { getWaveType } from '../utils'

const initialState = {
  figures: [],
  subject: null,
  ppgMode: { checked: true },
  hoverKey: null,
  measureMode: { checked: false },
  ppgHeight: null,
  waveHeight: null,
  hideRegions: false,
  ppg: [],
  selectedNodes: [],
  motionPPG: { checked: false },
  waveCheck: { Biphasic: true, Carotids: false, Unknown: false }
}

function addSelectedNodeReducer (state, action) {
  const nodeInfo = action.payload
  if (
    state.selectedNodes.findIndex(
      n => n.x === nodeInfo.x && n.y === nodeInfo.y
    ) !== -1
  ) {
    state.selectedNodes = state.selectedNodes.filter(
      n => n.x !== nodeInfo.x || n.y !== nodeInfo.y
    )
  } else {
    const nodeClass = getWaveType(state)

    state.selectedNodes = [...state.selectedNodes, { ...nodeInfo, nodeClass }]
  }
}

function classifyFigureReducer (state, action) {
  const { nodeClass, points } = action.payload

  const selectedPoints = state.ppg
    .filter(node => pointInPolygon([node.x, node.y], points))
    .map(node => {
      return { x: node.nx, y: node.ny, nodeClass }
    })
  const otherNodes = state.selectedNodes.filter(
    nodeInfo =>
      selectedPoints.findIndex(
        n => n.x === nodeInfo.x && n.y === nodeInfo.y
      ) === -1
  )
  state.figures = state.figures.concat(action.payload)
  state.selectedNodes = selectedPoints.concat(otherNodes)
}

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    setFigures: (state, action) => {
      state.figures = action.payload
    },
    addFigure: (state, action) => {
      state.figures = state.figures.concat(action.payload)
    },
    removeFigure: (state, action) => {
      state.figures = state.figures.filter(p => p.key !== action.payload)
    },
    initiateAssessment: () => initialState,
    setPpgMode: (state, action) => {
      state.ppgMode = action.payload
    },
    setMeasureMode: (state, action) => {
      state.measureMode = action.payload
    },
    setHoverKey: (state, action) => {
      state.hoverKey = action.payload
    },

    hideRegions: state => {
      state.hideRegions = true
    },
    clearFigures: state => {
      state.figures = []
    },
    setPpg: (state, action) => {
      state.ppg = action.payload
    },
    showRegions: state => {
      state.hideRegions = false
    },
    setSelectedNodes: (state, action) => {
      state.selectedNodes = action.payload
    },
    setWaveCheck: (state, action) => {
      state.waveCheck = action.payload
    },
    setMotionPPG: (state, action) => {
      state.motionPPG = action.payload
    },
    classifyFigure: classifyFigureReducer,
    addSelectedNode: addSelectedNodeReducer
  }
})

export const {
  addFigure,
  removeFigure,
  clearFigures,
  initiateAssessment,
  setPpg,
  setPpgMode,
  setMeasureMode,
  setHoverKey,
  setMotionPPG,
  hideRegions,
  showRegions,
  setWaveCheck,
  setSelectedNodes,
  classifyFigure,
  addSelectedNode
} = assessmentSlice.actions

export default assessmentSlice.reducer
