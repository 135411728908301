import { Circle } from 'react-konva'
import React from 'react'

export const NeckDot = props => {
  const {
    x,
    y,
    fill,
    stroke,
    opacity,
    onMouseDown,
    size,
    onMouseEnter,
    onMouseLeave
  } = props

  return (
    <Circle
      x={x}
      y={y}
      width={size}
      height={size}
      opacity={opacity}
      fill={fill}
      stroke={stroke}
      strokeWidth={3}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
