import Card from '@material-ui/core/Card'
import { Grid } from '@material-ui/core'
import { ConditionBadge } from '../components/ConditionBadge'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import React from 'react'
import { Link } from 'wouter'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap',
    maxWidth: 1420
  },
  root: {
    minWidth: 450,
    maxWidth: 450,

    minHeight: 450,
    position: 'relative'
  },

  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },

  badgeContainer: {
    padding: 5,
    width: 110,
    height: '100%',
    float: 'right',
    paddingTop: 10
  }
}))

export const ClinicalCard = props => {
  const classes = useStyles()
  const {
    clinician,
    date,
    condition,
    clinicalAttributes,
    note,
    jvpHeight,
    ANGLE,
    SUBJECT
  } = props
  const { hr, rr, spO2, weight, BPTop, BPBottom } = clinicalAttributes
  return (
    <Card className={classes.root} variant='outlined'>
      <div className={classes.badgeContainer}>
        <Grid container direction='column'>
          <Grid item>
            <ConditionBadge {...condition} />
          </Grid>
          <Grid item>
            <Typography variant='button'>HR: {hr || 'N/A'} </Typography>
          </Grid>
          <Grid item>
            <Typography variant='button'> RR: {rr || 'N/A'} </Typography>
          </Grid>
          <Grid item>
            <Typography variant='button'>
              {' '}
              BP: {BPTop || 'N/A'}/{BPBottom || 'N/A'}{' '}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='button'> Sp02: {spO2}% </Typography>
          </Grid>
          <Grid item>
            <Typography variant='button'> Weight: {weight}kg </Typography>
          </Grid>
          <Grid item>
            <Typography variant='button'> JVP: {jvpHeight}cm </Typography>
          </Grid>
        </Grid>
      </div>
      <CardContent>
        <Typography
          className={classes.title}
          color='textSecondary'
          gutterBottom
        >
          {clinician}
        </Typography>
        <Typography variant='h5' component='h2'>
          {new Date(date.seconds * 1000).toDateString()}
        </Typography>
        <div style={{ height: 330, overflowY: 'scroll' }}>
          <Typography variant='body1' component='span' display='inline'>
            {note
              ? note.split('\n').map((i, key) => {
                  return <p key={key}>{i}</p>
                })
              : ''}
          </Typography>
        </div>
      </CardContent>
      <CardActions style={{ position: 'absolute', bottom: 0 }}>
        <Grid container>
          <Grid item>
            <Button size='small'>View Capture</Button>
          </Grid>
          <Grid item>
            <Link to={`/assess/${SUBJECT}/${ANGLE}`}>
              <Button size='small'>Assess</Button>
            </Link>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
