import { Badge, Grid, Paper } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import WellnessIcon from './WellnessIcon'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import AirlineSeatLegroomExtraIcon from '@material-ui/icons/AirlineSeatLegroomExtra'
import LoopIcon from '@material-ui/icons/Loop'
import NightsStayIcon from '@material-ui/icons/NightsStay'
import AirlineSeatFlatAngledIcon from '@material-ui/icons/AirlineSeatFlatAngled'
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Tooltip from '@material-ui/core/Tooltip'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const useStyles = makeStyles(theme => ({
  iconYes: { color: theme.palette.selectors.first },
  iconNo: { color: theme.palette.selectors.fourth }
}))

const statusProgression = code => {
  let badgeIcon
  switch (code) {
    case -2:
      badgeIcon = <NavigateNextIcon />
      break
    case 2:
      badgeIcon = <NavigateBeforeIcon />
      break
    default:
      badgeIcon = null
  }
  return badgeIcon
}

export const ConditionBadge = props => {
  const { wellnessCode, trend } = props
  let BadgeIcon
  switch (trend) {
    case -1:
      BadgeIcon = NavigateBeforeIcon
      break
    case 1:
      BadgeIcon = NavigateNextIcon
      break
    default:
      BadgeIcon = Paper
  }
  return (
    <LightTooltip title={<ConditionElements {...props} />}>
      <Badge badgeContent={<BadgeIcon />}>
        <WellnessIcon code={wellnessCode} />
      </Badge>
    </LightTooltip>
  )
}

const ConditionIcon = props => {
  const { code, Icon } = props
  const classes = useStyles()
  const conditionClass = condition =>
    condition ? classes.iconYes : classes.iconNo

  return (
    <Badge
      badgeContent={statusProgression(code)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: code < 0 ? 'right' : 'left'
      }}
    >
      <Icon className={conditionClass(code < 0)} />
    </Badge>
  )
}

const ConditionElements = props => {
  const { shortness, swelling, dizzy, night, propped } = props

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <ConditionIcon Icon={CloudOffIcon} code={shortness} />
      </Grid>
      <Grid item>
        <ConditionIcon Icon={AirlineSeatLegroomExtraIcon} code={swelling} />
      </Grid>
      <Grid item>
        <ConditionIcon Icon={LoopIcon} code={dizzy} />
      </Grid>
      <Grid item>
        <ConditionIcon Icon={NightsStayIcon} code={night} />
      </Grid>
      <Grid item>
        <ConditionIcon Icon={AirlineSeatFlatAngledIcon} code={propped} />
      </Grid>
    </Grid>
  )
}
