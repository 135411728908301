// @flow
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import dashboardReducer from './dashboardSlice'
import assessmentReducer from './assessmentSlice'
import controlReducer from './controlSlice'
const reducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  dashboard: dashboardReducer,
  assessment: assessmentReducer,
  controls: controlReducer
})

const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})

window.store = store

export default store
