import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { Grid } from '@material-ui/core'
import { ClinicalCard } from './ClinicalCard'
import { ClinicalGraph } from './ClinicalGraph'
import { ECGGraph } from './ECGGraph'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'

import { setCard } from '../redux/dashboardSlice'

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap',
    maxWidth: 1420
  },
  empty: {
    minWidth: 450,
    maxWidth: 450,

    minHeight: 300,
    position: 'relative'
  }
}))

const ClinicalContainer = props => {
  const { subject } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const clinician = useSelector(state => state.firebase.auth.email)

  useFirestoreConnect([
    {
      collection: 'clinicians',
      doc: clinician,
      subcollections: [{ collection: `/assessments/${subject}/observations` }],
      storeAs: 'assessments',
      type: 'once'
    },
    {
      collection: 'analysisInfo',
      type: 'once',
      where: ['SUBJECT', '==', subject],
      orderBy: ['date']
    }
  ])

  // useEffect(()=>{dispatch(initialize(subject))}, [subject])

  const currentCard = useSelector(state => state.dashboard.currentCard)

  const patientData = useSelector(state => state.firestore.data.analysisInfo)
  const { assessments } = useSelector(state => state.firestore.data)
  // const [currentCard, setCurrentCard] = useState(1)
  if (!patientData) {
    return null
  }

  const cards = Object.keys(patientData).map(key => {
    const data = patientData[key]
    const assessment =
      assessments && assessments[data.ANGLE] ? assessments[data.ANGLE] : {}
    return (
      <Grid key={`card-grid-${key}`} item>
        <ClinicalCard
          date={1626440153}
          clinicalAttributes={{}}
          key={`clinical-card-${key}`}
          {...data}
          {...assessment}
        />
      </Grid>
    )
  })

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item>
        <ECGGraph ecg={[1, 1, 1, 1, 1, 1, 1]} />
      </Grid>

      <Grid item>
        <ClinicalGraph
          onValueClick={e => dispatch(setCard(e.measure))}
          currentCard={currentCard}
          patientData={Object.values(patientData).map(d => {
            return {
              ...d,
              jvpHeight:
                assessments &&
                assessments[d.ANGLE] &&
                assessments[d.ANGLE].jvpHeight
                  ? assessments[d.ANGLE].jvpHeight
                  : 0
            }
          })}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          direction='row'
          alignItems='stretch'
          justify='flex-start'
        >
          {currentCard > 0 ? (
            cards[currentCard - 1]
          ) : (
            <Card className={classes.empty} variant='outlined' />
          )}
          {cards[currentCard]}
          {currentCard < cards.length - 1 ? (
            cards[currentCard + 1]
          ) : (
            <Card className={classes.empty} variant='outlined' />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClinicalContainer
