// const apiUrl =
// //   'https://jvp-analysis-jb2hlnph3q-uc.a.run.app/v1/files/ppg_array587997.json'
// const apiUrl =
//   'https://jvp-analysis-jb2hlnph3q-uc.a.run.app/v1/files/587997-30-nodes.json'

// const apiUrl = 'http://localhost:3000/587997-30-nodes.json'
async function fetchNode ({ accessToken, subject, angle, variant = 'hac' }) {
  const apiUrl = `https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ppg_array_${subject}_${angle}_${variant}.json`

  const response = await fetch(apiUrl, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`
    })
  })

  if (!response.ok) {
    const error = await response.json()
    console.warn(error)

    return []
  } else {
    const result = await response.json()

    return result
  }
}

window.fetchNode = fetchNode
export default fetchNode
