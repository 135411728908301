import { LineSeries, XYPlot } from 'react-vis'
import React, { useEffect, useState } from 'react'
import fetchNode from '../api/fetchNode'
import { useSelector } from 'react-redux'
import { getAccessToken } from '../redux/authSelector'
import Grid from '@material-ui/core/Grid'
import fetchImage from '../api/fetchImage'
import getCroppedImg from '../common/cropImage'

const PPGClassificationContainer = props => {
  const accessToken = useSelector(getAccessToken)

  const angle = 45
  const subject = 587997
  const url =
    'https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/waveform_571942_45.png'
  const [ppg, setPPG] = useState([])
  const [image, setImage] = useState(null)

  const theData = ppg.length && ppg[55]

  useEffect(() => {
    async function getNode () {
      const ppg = await fetchNode({
        accessToken,
        angle,
        subject
      })
      const image = await fetchImage({ accessToken, url })
      setImage(image)
      setPPG(ppg)
    }
    getNode()
    return []
  }, [accessToken, angle, subject])

  // useEffect(()=>image && getCroppedImg(
  //     URL.createObjectURL(image),
  //     {width: 58, height: 60, x: 60 * 15 + 0, y: 60 * 10 + 1}
  //
  //
  //   ).then(x=>setCropped(x)), [subject, image])

  if (!theData || !image) {
    return null
  }

  return (
    <PPGClassificationComponent
      data={theData}
      nodeX={10}
      nodeY={6}
      image={image}
      xMaxima={20}
    />
  )
}

const segmentSeries = (breakpoint, data) => {
  const colors = { 0: 'red', 1: 'blue' }
  const segment = data.slice(breakpoint.start, breakpoint.end)
  return (
    <LineSeries
      data={segment.map((y, i) => {
        return { x: i + breakpoint.start, y: y }
      })}
      color={colors[breakpoint.class] ? colors[breakpoint.class] : 'black'}
    />
  )
}

export const PPGClassificationComponent = props => {
  const [cropped, setCropped] = useState(null)
  const { data, nodeX, nodeY, image, xMaxima } = props
  useEffect(
    () =>
      image &&
      getCroppedImg(URL.createObjectURL(image), {
        width: 80,
        height: 80,
        x: 56 * (xMaxima - nodeX - 1) - 5,
        y: 54 * nodeY + 8
      }).then(x => setCropped(x)),
    [image, nodeX, nodeY, xMaxima]
  )
  if (!data) {
    return null
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
    >
      <Grid item>
        <div style={{ margin: 5 }}>
          <img
            src={cropped}
            alt='Cropped'
            style={{ width: 100, height: 100 }}
          />
        </div>
      </Grid>

      <Grid item>
        <XYPlot width={400} height={100}>
          <LineSeries
            data={
              data &&
              data.ppg.map((y, i) => {
                return { x: i, y: y }
              })
            }
          />
          {data.labels.map(breakpoint => segmentSeries(breakpoint, data.ppg))}
        </XYPlot>
      </Grid>
    </Grid>
  )
}

export default PPGClassificationContainer
