import React from 'react'
import { Layer } from 'react-konva'
import { NeckDot } from './NeckDot'

// This layer only required for debugging

const ImagePlacementComponent = props => {
  const { ppg, selectedNodes, theme, onMouseEnter, opacity } = props
  const colorBySelection = (ppgNode, selectedNodes, theme) => {
    const nodeSelect = selectedNodes.filter(
      node => node.x === ppgNode.nx && node.y === ppgNode.ny
    )
    if (nodeSelect.length) {
      if (nodeSelect[0].nodeClass === 'Carotids') {
        return theme.palette.selectors.first
      } else if (nodeSelect[0].nodeClass === 'Unknown') {
        return theme.palette.selectors.second
      }
      return theme.palette.accent.main
    }

    return theme.palette.primary.main
  }
  return (
    <Layer id='selector-dots'>
      {ppg.map(cir => (
        <NeckDot
          key={`node ${cir.nx} ${cir.ny} `}
          x={cir.x}
          y={cir.y}
          row={cir.nx}
          column={cir.ny}
          fill={colorBySelection(cir, selectedNodes, theme)}
          size={4}
          opacity={opacity}
          onMouseEnter={e => onMouseEnter(cir.ny, cir.nx)}
        />
      ))}
    </Layer>
  )
}

export default ImagePlacementComponent
