// @flow

import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Link as RouterLink } from 'wouter'
import { useSnackbar } from 'notistack'

import ResetPassword from '../components/ResetPassword'

function ResetPasswordContainer () {
  const [email, setEmail] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(undefined)
  const firebase = useFirebase()
  const snackbar = useSnackbar()

  function handleResetPassword (event) {
    if (email === '') {
      setError('Enter your email')
    } else {
      setError(undefined)
      setFetching(true)
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => setFetching(false))
        .then(() =>
          snackbar.enqueueSnackbar('Password reset sent. Check your inbox! 🎉')
        )
        .catch(error => {
          console.log({ error })
          setFetching(false)
          setError(error.message)
        })
    }
  }

  return (
    <ResetPassword
      handleResetPassword={handleResetPassword}
      email={email}
      error={error}
      fetching={fetching}
      setEmail={setEmail}
      RouterLink={RouterLink}
    />
  )
}

export default ResetPasswordContainer
