import { Card } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import HoverTableComponent from '../components/HoverTableComponent'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { removeFigure, setHoverKey } from '../redux/assessmentSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleClassification,
  toggleNodeLocations,
  setOpacity,
  setWaveform
} from '../redux/controlSlice'
import WaveformControls from '../components/WaveformControls'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minWidth: 450,
    maxWidth: 450,
    top: '10%',
    left: 0,
    zIndex: 1,
    maxHeight: 600,
    overflowY: 'scroll'
  }
}))

const columns = [
  { id: 'key', label: 'ID', minWidth: 170 },
  {
    id: 'nodeClass',
    label: 'Classification',
    minWidth: 170,
    align: 'center'
  }
]

const WaveformControlsContainer = props => {
  const { subject, angle } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const { figures } = useSelector(state => state.assessment)
  const { nodeLocations, classificationView, opacity, waveform } = useSelector(
    state => state.controls
  )

  const record = useSelector(state => state.firestore.data.analysisInfo)
  // FIXME: this is a workaround as we have to rely on the name of the layer to swap between assessemnts see comppoent arg
  if (!record) {
    return null
  }

  const algorithms = Object.values(record).length
    ? Object.values(record).filter(
        r => r.SUBJECT === String(subject) && r.ANGLE === parseInt(angle)
      )[0].waveforms
    : []

  if (!waveform && algorithms.length) {
    dispatch(setWaveform(algorithms[0].label))
  }
  const deleteCell = row => {
    return (
      <TableCell key={row.key + 'buttons'}>
        <Button>
          <DeleteIcon onClick={() => dispatch(removeFigure(row.key))} />
        </Button>
      </TableCell>
    )
  }

  return (
    <Card className={classes.root} raised>
      <WaveformControls
        nodeLocation={nodeLocations}
        classificationView={classificationView}
        opacity={opacity}
        setOpacity={(event, newValue) => dispatch(setOpacity(newValue))}
        toggleNodes={() => dispatch(toggleNodeLocations())}
        toggleClassification={() => dispatch(toggleClassification())}
        algorithms={Object.keys(algorithms).map(x => {
          return { label: x, value: x }
        })}
        waveform={waveform}
        setWaveform={e => dispatch(setWaveform(e))}
      />
      <HoverTableComponent
        columns={columns}
        rows={figures}
        keyValue='key'
        customCells={deleteCell}
        onHoverIn={row => dispatch(setHoverKey(row.key))}
        onHoverOut={() => dispatch(setHoverKey(null))}
      />
    </Card>
  )
}

export default WaveformControlsContainer
