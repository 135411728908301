// @flow

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { Link as RouterLink, useLocation } from 'wouter'

import SignUp from '../components/SignUp'
import { isAuthenticated } from '../redux/authSelector'

function SignUpContainer () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(null)
  const setLocation = useLocation()[1]
  const firebase = useFirebase()
  const authenticated = useSelector(isAuthenticated)

  // If user is authenticated but routing to this page, then user
  // lacks authorization to use this app.
  const noPermissionError = `Your account ${authenticated} does not have permission to use this app. Try signing in with another account.`

  function handleSignUp () {
    if (email === '') {
      setError('Enter your email')
    } else if (password === '') {
      setError('Enter your password')
    } else {
      setError(null)
      setFetching(true)
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          setFetching(false)
          setLocation('/')
        })
        .catch(error => {
          setFetching(false)
          setError(error.message)
        })
    }
  }

  function handleGoogleSignUp (event) {
    setError(undefined)
    setFetching(true)

    firebase
      .login({
        provider: 'google',
        type: 'popup'
        // scopes: ['email'] // not required
      })
      .then(() => {
        setFetching(false)
        setLocation('/')
      })
  }

  return (
    <SignUp
      handleSignUp={handleSignUp}
      handleGoogleSignUp={handleGoogleSignUp}
      email={email}
      password={password}
      error={authenticated ? noPermissionError : error}
      fetching={fetching}
      setEmail={setEmail}
      setPassword={setPassword}
      RouterLink={RouterLink}
    />
  )
}

export default SignUpContainer
