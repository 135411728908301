import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Link } from 'wouter'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from '../redux/dashboardSlice'
import { useFirestoreConnect } from 'react-redux-firebase'
const columns = [
  { id: 'name', label: 'Patient Name', minWidth: 170 },
  {
    id: 'clinician',
    label: 'Clinician',
    minWidth: 100,
    format: value => value.toLocaleString('en-US')
  },
  {
    id: 'assessment',
    label: 'Assessment Required',
    minWidth: 170,
    align: 'center'
  },
  {
    id: 'lastReading',
    label: 'Last Reading',
    minWidth: 170,
    align: 'center',
    format: value => `${value} Days`
  },
  {
    id: 'chartReview',
    label: 'Last Chart Review',
    minWidth: 170,
    align: 'right',
    format: value => `${value} Days`
  },
  {
    id: 'patientStatus',
    label: 'Patient Status',
    minWidth: 170,
    align: 'right'
  }
]

function createData (
  name,
  clinician,
  assessment,
  lastReading,
  chartReview,
  patientStatus
) {
  return {
    name,
    clinician,
    assessment,
    lastReading,
    chartReview,
    patientStatus
  }
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    height: '100%'
  }
})

const MISSED_CAPTURE_WINDOW = 6

export const PatientSelectionContainer = () => {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const INITIAL_STATUS = {
    active: true,
    mine: false,
    new: false,
    review: false,
    missed: false
  }

  useFirestoreConnect([
    {
      collection: 'patient-list',
      storeAs: 'patients',
      type: 'once'
    }
  ])
  const [patientFilter, setPatientFilter] = useState(INITIAL_STATUS)
  const dispatch = useDispatch()

  const patients = useSelector(state => state.firestore.data.patients)

  const rows =
    patients && Object.values(patients).length > 0
      ? Object.values(patients).map(r =>
          createData(r.ehrInfo.healthID, r.ehrInfo.primaryClinician, 'Yes')
        )
      : []
  // createData(587997, 'Suzy Smith MD', 'Yes', 5, 6, 'Active'),

  // const [clinician, setClinian] = useState('Suzy Smith MD')
  const clinician = 'Suzy Smith MD'
  const filterRecords = record => {
    if (patientFilter.active && record.patientStatus === 'Inactive') {
      return false
    }
    if (patientFilter.mine && record.clinician !== clinician) {
      return false
    }
    if (patientFilter.new && record.assessment === 'No') {
      return false
    }
    if (patientFilter.review && record.lastReading >= record.chartReview) {
      return false
    }
    if (patientFilter.missed && record.lastReading > MISSED_CAPTURE_WINDOW) {
      return false
    }
    return true
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  const handleFilterBoxes = event => {
    setPatientFilter({
      ...patientFilter,
      [event.target.name]: event.target.checked
    })
  }
  const filteredRows = rows.filter(filterRecords)
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                    <TableCell key={row.id + 'buttons'}>
                      <Link to={`/dash/${row.name}`}>
                        <Button onClick={() => dispatch(initialize(row.name))}>
                          View Dashboard
                        </Button>
                      </Link>
                      <Button>Generate Report</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                name='mine'
                checked={patientFilter.mine}
                onChange={handleFilterBoxes}
              />
            }
            label='My Patients'
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={<Checkbox name='new' onChange={handleFilterBoxes} />}
            label='New Reading'
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                name='missed'
                checked={patientFilter.missed}
                onChange={handleFilterBoxes}
              />
            }
            label='Missed Readings'
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={<Checkbox name='review' onChange={handleFilterBoxes} />}
            label='Chart Review'
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                name='active'
                checked={patientFilter.active}
                onChange={handleFilterBoxes}
              />
            }
            label='Active Clinic Patients'
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
