import React from 'react'
import FlowSelector from '../components/FlowSelector'
import { Grid } from '@material-ui/core'

const OptionContainer = props => {
  const {
    setCheck,
    checked,
    measureMode,
    setMeasureMode,
    motion,
    setMotion
  } = props

  return (
    <Grid container direction='row' justifyContent='space-between'>
      <Grid item>
        <FlowSelector
          onOption='Classify'
          offOption='Measure'
          setCheck={setMeasureMode}
          checkBox={measureMode}
        />
      </Grid>
      <Grid item>
        <FlowSelector
          setCheck={setCheck}
          checkBox={checked}
          onOption='Columns'
          offOption='Rows'
        />
      </Grid>
      <Grid item>
        <FlowSelector
          setCheck={setMotion}
          checkBox={motion}
          onOption='PPG'
          offOption='Motion'
        />
      </Grid>
    </Grid>
  )
}
export default OptionContainer
