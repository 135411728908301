const apiUrl =
  'https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ref_frame587997.jpg'

async function fetchImage ({ url = apiUrl, accessToken }) {
  const response = await fetch(url, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`
    })
  })

  if (!response.ok) {
    const error = await response.json()
    console.warn(error)

    return null
  } else {
    const result = await response.blob()

    return result
  }
}

window.fetchImage = fetchImage
export default fetchImage
