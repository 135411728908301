import { useTheme } from '@material-ui/core'
import {
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot
} from 'react-vis'
import React from 'react'

export const ECGGraph = props => {
  const { ecg } = props
  const theme = useTheme()
  return (
    <XYPlot
      width={window.innerWidth - 500}
      height={(window.innerWidth - 500) / 8}
      colorType='literal'
    >
      <HorizontalGridLines />
      <VerticalGridLines tickTotal={30} />
      <XAxis
        title='seconds'
        tickValues={[...Array(Math.floor(ecg.length / 100)).keys()]}
      />
      <LineSeries
        data={ecg.map((e, i) => {
          return { x: i / 100, y: e }
        })}
        color={theme.palette.accent.dark}
      />
    </XYPlot>
  )
}
