import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Layer, Stage } from 'react-konva'
import { useTheme } from '@material-ui/core'

import JwtKonvaImage from '../components/JwtKonvaImage'
import { getAccessToken } from '../redux/authSelector'
import { selectedColor } from '../utils'
import { NeckDot } from '../components/NeckDot'

const SelectorContainer = props => {
  const theme = useTheme()
  const {
    selectedRow,
    setSelectedRow,
    checked,
    neckUrl = 'https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ref_frame587997.jpg',
    width = 480,
    height = 300
  } = props

  const [toggled, setToggled] = useState(false)
  const { ppg, selectedNodes } = useSelector(state => state.assessment)
  const accessToken = useSelector(getAccessToken)
  const viewMode = checked.checked ? 'rows' : 'columns'

  // TODO: Add spinner or other loading indicator while awaiting fetchNode
  if (ppg.length === 0) {
    return null
  }

  const filterFunctionality = cir => {
    if (
      selectedNodes.filter(n => n.x === cir.nx && n.y === cir.ny).length !== 0
    ) {
      const node = selectedNodes.filter(
        n => n.x === cir.nx && n.y === cir.ny
      )[0]
      return selectedColor(node.x, node.y, node.nodeClass, selectedNodes, theme)
    }
    return selectedColor(cir.nx, cir.ny, cir.nodeClass, selectedNodes, theme)
  }
  return (
    <Stage width={width} height={height}>
      <Layer>
        <JwtKonvaImage
          url={neckUrl}
          accessToken={accessToken}
          width={width}
          height={height}
        />
        {ppg.map(cir => (
          <NeckDot
            key={`node ${cir.nx} ${cir.ny} `}
            x={cir.x}
            y={cir.y}
            row={cir.nx}
            column={cir.ny}
            stroke={filterFunctionality(cir)}
            fill={
              cir[viewMode === 'rows' ? 'nx' : 'ny'] === selectedRow
                ? theme.palette.accent.main
                : theme.palette.primary.main
            }
            size={6}
          />
        ))}
        {ppg.map(cir => (
          <NeckDot
            key={`CLICK ${cir.nx} ${cir.ny} `}
            x={cir.x}
            y={cir.y}
            row={cir.nx}
            opacity={0.5}
            column={cir.ny}
            fill={
              cir[viewMode === 'rows' ? 'nx' : 'ny'] === toggled
                ? theme.palette.accent.main
                : null
            }
            stroke={null}
            onMouseDown={e =>
              setSelectedRow(cir[viewMode === 'rows' ? 'nx' : 'ny'])
            }
            size={10}
            onMouseEnter={() => {
              setToggled(viewMode === 'rows' ? cir.nx : cir.ny)
            }}
            onMouseLeave={() => setToggled(null)}
          />
        ))}
      </Layer>
    </Stage>
  )
}

export default SelectorContainer
