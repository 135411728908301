import { Route, Switch } from 'wouter'
import { useSelector } from 'react-redux'

import { isAuthorized, isLoading, getAccessToken } from '../redux/authSelector'
import LoadingContainer from '../containers/LoadingContainer'
import SignInContainer from '../containers/SignInContainer'
import SignUpContainer from '../containers/SignUpContainer'
import ResetPasswordContainer from '../containers/ResetPasswordContainer'
import ViewerContainer from '../containers/ViewerContainer'
import { PatientSelectionContainer } from './PatientSelectionContainer'
import AssessmentContainer from './AssessmentContainer'
import WaveformContainer from './WaveformContainer'
import PPGClassificationContainer from './PPGClassificationContainer'

function RouteContainer () {
  const authorized = useSelector(isAuthorized)
  const loading = useSelector(isLoading)

  // TODO: Demonstrating access token selector below.
  // Will remove once API fetch with access token implemented.
  const accessToken = useSelector(getAccessToken)
  window.accessToken = accessToken

  // TODO: rewrite path on login to '/'
  return (
    <>
      {loading && (
        <Switch>
          <Route component={LoadingContainer} />
        </Switch>
      )}
      {!loading && authorized && (
        <Switch>
          <Route path='/' component={PatientSelectionContainer} />
          <Route path='/wave' component={WaveformContainer} />
          <Route
            path='/classification'
            component={PPGClassificationContainer}
          />

          <Route path='/dash/:subject'>
            {params => <ViewerContainer subject={params.subject} />}
          </Route>
          <Route path='/assess/:subject/:angle'>
            {params => (
              <AssessmentContainer
                subject={params.subject}
                angle={params.angle}
              />
            )}
          </Route>
        </Switch>
      )}
      {!loading && !authorized && (
        <Switch>
          <Route path='/resetpassword' component={ResetPasswordContainer} />
          <Route path='/signup' component={SignUpContainer} />
          <Route path='/signin' component={SignInContainer} />
          <Route path='/' component={SignInContainer} />
          <Route component={SignInContainer} />
        </Switch>
      )}
    </>
  )
}

export default RouteContainer
