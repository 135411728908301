// @flow

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { Link as RouterLink, useLocation } from 'wouter'

import SignIn from '../components/SignIn'
import { isAuthenticated } from '../redux/authSelector'

function SignInContainer () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(undefined)
  const setLocation = useLocation()[1]
  const firebase = useFirebase()
  const authenticated = useSelector(isAuthenticated)

  // If user is authenticated but routing to this page, then user
  // lacks authorization to use this app.
  const noPermissionError = `Your account ${authenticated} does not have permission to use this app. Try signing in with another account.`

  function handleLogin (event) {
    if (email === '') {
      setError('Enter your email')
    } else if (password === '') {
      setError('Enter your password')
    } else {
      setError(undefined)
      setFetching(true)
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          setFetching(false)
          setLocation('/')
        })
        .catch(error => {
          console.log({ error })
          setFetching(false)
          setError(error.message)
        })
    }
  }

  function handleGoogleLogin (event) {
    setError(undefined)
    setFetching(true)

    firebase
      .login({
        provider: 'google',
        type: 'popup'
        // scopes: ['email'] // not required
      })
      .then(() => {
        setFetching(false)
        setLocation('/')
      })
  }

  return (
    <SignIn
      handleLogin={handleLogin}
      handleGoogleLogin={handleGoogleLogin}
      email={email}
      password={password}
      error={authenticated ? noPermissionError : error}
      fetching={fetching}
      setEmail={setEmail}
      setPassword={setPassword}
      RouterLink={RouterLink}
    />
  )
}

export default SignInContainer
