import React from 'react'

import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useTheme } from '@material-ui/core'

const WellnessIcon = props => {
  const theme = useTheme()
  const { code } = props
  let BadgeIcon
  switch (code) {
    case 1:
      BadgeIcon = SentimentVeryDissatisfiedIcon
      break
    case 2:
      BadgeIcon = SentimentDissatisfiedIcon
      break
    case 3:
      BadgeIcon = SentimentSatisfiedIcon
      break
    case 4:
      BadgeIcon = SentimentSatisfiedAltIcon
      break
    case 5:
      BadgeIcon = SentimentVerySatisfiedIcon
      break
    default:
      BadgeIcon = HelpOutlineIcon
  }
  return <BadgeIcon style={{ color: theme.palette.accent.main }} />
}

export default WellnessIcon
