import { useTheme } from '@material-ui/core'
import {
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis'
import React from 'react'

export const ClinicalGraph = props => {
  const theme = useTheme()
  const { onValueClick, currentCard, patientData } = props
  return (
    <XYPlot
      width={window.innerWidth - 500}
      height={(window.innerWidth - 500) / 8}
      xType='time'
      colorType='literal'
    >
      <HorizontalGridLines />
      <VerticalGridLines />
      <XAxis title='Date' />
      <YAxis title='JVP Height' />
      <LineMarkSeries
        stroke={theme.palette.secondary.main}
        data={patientData.map((pd, i) => {
          return {
            x: new Date(pd.date.seconds * 1000),
            y:
              pd.jvpHeight ||
              (pd.clinicalAttributes && pd.clinicalAttributes.jvpHeight) ||
              1,
            measure: i,
            color:
              currentCard === i
                ? theme.palette.accent.main
                : theme.palette.primary.main
          }
        })}
        onValueClick={onValueClick}
      />
    </XYPlot>
  )
}
