import React, { useEffect, useRef, useState } from 'react'
import { PPGClassificationComponent } from '../containers/PPGClassificationContainer'
import { Layer, Rect, Stage } from 'react-konva'
import JwtKonvaImage from './JwtKonvaImage'
import ImagePlacementComponent from './ImagePlacementComponent'
import LineDrawComponent from './LineDrawComponent'
import { figureColor } from '../utils'
import { Fab, useTheme } from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { makeStyles } from '@material-ui/core/styles'

const scaleBy = 0.9

const splitAngledOffset = (x, y, rotation) => {
  const yComponent =
    x * Math.sin((Math.PI * rotation) / 180) +
    y * Math.cos((Math.PI * rotation) / 180)
  const xComponent =
    x * Math.cos((Math.PI * rotation) / 180) -
    y * Math.sin((Math.PI * rotation) / 180)
  return { xOffset: xComponent, yOffset: yComponent }
}

function downloadURI (uri, name) {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minWidth: 450,
    maxWidth: 450,
    top: '10%',
    left: 0,
    zIndex: 1,
    maxHeight: 600,
    overflowY: 'scroll'
  },

  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },

  badgeContainer: {
    padding: 5,
    width: 110,
    height: '100%',
    float: 'right',
    paddingTop: 10
  },
  container: {
    height: '85vh',
    zIndex: 0,
    width: '74vw',
    overflow: 'scroll'
  },
  floatButton: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed'
  },
  classifyContainer: {
    position: 'fixed',
    width: 510,
    height: 100,
    top: 200,
    backgroundColor: 'white',
    right: 20,
    zIndex: 10
  }
}))

const WaveformCanvas = props => {
  const {
    captureInfo,
    image,
    hoverKey,
    lines,
    neckUrl,
    setDraggable,
    regions,
    nodeLocations,
    rotation,
    selectedNodes,
    classificationView,
    figures,
    hideRegions,
    handleMouseMove,
    handleMouseUp,
    handleMouseDown,
    ppg,
    draggable,
    accessToken,
    url,
    opacity,
    angle,
    subject,
    stageRef,
    shape
  } = props

  const [waveWidth, waveHeight] = shape

  const classes = useStyles()
  const theme = useTheme()

  const [nodeX, setNodeX] = useState(0)
  const [nodeY, setNodeY] = useState(0)

  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)

  const stageCanvasRef = useRef(null)

  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      setHeight(stageCanvasRef.current.clientHeight)
      setWidth(stageCanvasRef.current.clientWidth)
    }
  }, [stageCanvasRef])

  function zoomStage (event) {
    // pulled from https://colinwren.medium.com/adding-zoom-and-panning-to-your-react-konva-stage-3e0a38c31d38
    event.evt.preventDefault()
    if (stageRef.current !== null) {
      const stage = stageRef.current
      const oldScale = stage.scaleX()
      const { x: pointerX, y: pointerY } = stage.getPointerPosition()
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale
      }
      const newScale =
        event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy
      stage.scale({ x: newScale, y: newScale })
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale
      }
      stage.position(newPos)
      stage.batchDraw()
    }
  }

  const handleExport = () => {
    const uri = stageRef.current.toDataURL()
    const date = new Date()
    downloadURI(
      uri,
      `${date.toISOString().split('T')[0]}_${subject}-${angle}.png`
    )
  }
  // NOTE: this has 4 terms for coords however we only need 2 in this component.
  const [xmin, ymax] = captureInfo.envelope

  const nodeOnMouseIn = (nodeX, nodeY) => {
    setNodeX(nodeX)
    setNodeY(nodeY)
  }

  const { xOffset, yOffset } = splitAngledOffset(-5, 5, rotation)

  return (
    <>
      {classificationView && (
        <div className={classes.classifyContainer}>
          <PPGClassificationComponent
            data={ppg.filter(p => p.nx === nodeY && p.ny === nodeX)[0]}
            nodeX={nodeX}
            nodeY={nodeY}
            image={image}
            xMaxima={20}
          />
        </div>
      )}
      <div className={classes.container} ref={stageCanvasRef}>
        <Stage
          width={Math.min(width, 1600) || 0}
          height={height || 0}
          draggable={draggable}
          onWheel={zoomStage}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onContextMenu={e => {
            e.evt.preventDefault()
            setDraggable(true)
          }}
          ref={stageRef}
          rotation={90 - rotation}
          x={500}
          // NOTE: this is great for debugging
          // onClick={e =>
          //   console.log(getRelativePointerPosition(e.target.getStage()))
          // }
          onClick={e => console.log(e)}
        >
          <Layer id='stuffToShow'>
            <JwtKonvaImage
              url={neckUrl}
              accessToken={accessToken}
              width={480}
              height={300}
            />
          </Layer>
          {!nodeLocations && (
            <Layer
              id='waveform'
              listening={false}
              x={xmin + xOffset}
              y={ymax + yOffset}
            >
              <Rect
                height={waveHeight + 10}
                width={waveWidth + 10}
                fill='#ffffff'
                opacity={opacity / 100}
                rotation={-90 + rotation}
              />

              <JwtKonvaImage
                accessToken={accessToken}
                url={url}
                height={10 + waveHeight}
                width={10 + waveWidth}
                rotate={-90 + rotation}
              />
            </Layer>
          )}
          {
            <ImagePlacementComponent
              ppg={ppg}
              selectedNodes={selectedNodes}
              theme={theme}
              onMouseEnter={nodeOnMouseIn}
              opacity={nodeLocations ? 1 : 0}
            />
          }

          <LineDrawComponent
            id='session_drawings'
            lines={figures}
            getWidth={line => (line.key === hoverKey ? 4 : 2)}
            getLineColor={line => figureColor(line.nodeClass, theme)}
            dash={[5, 10]}
          />
          {!hideRegions && (
            <LineDrawComponent
              id='committed_drawings'
              lines={regions || []}
              getWidth={line => (line.key === hoverKey ? 4 : 2)}
              getLineColor={line => figureColor(line.nodeClass, theme)}
            />
          )}

          <LineDrawComponent
            id='current_drawing'
            lines={lines}
            getWidth={line => (line.key === hoverKey ? 4 : 2)}
            getLineColor={line => figureColor(line.nodeClass, theme)}
          />
        </Stage>
        <Fab className={classes.floatButton} onClick={handleExport}>
          <PhotoCameraIcon />
        </Fab>
      </div>
    </>
  )
}

export default WaveformCanvas
