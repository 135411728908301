import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken } from '../redux/authSelector'
import { getNodeClass, uuidv4 } from '../utils'
import { classifyFigure } from '../redux/assessmentSlice'
import fetchImage from '../api/fetchImage'
import { useFirestoreConnect } from 'react-redux-firebase'
import WaveformCanvas from '../components/WaveformCanvas'

function getRelativePointerPosition (node) {
  // the function will return pointer position relative to the passed node
  const transform = node.getAbsoluteTransform().copy()
  // to detect relative position we need to invert transform
  transform.invert()

  // get pointer (say mouse or touch) position
  const pos = node.getStage().getPointerPosition()

  // now we find relative point
  return transform.point(pos)
}

const WaveformContainer = props => {
  // https://stackoverflow.com/questions/54073037/is-it-possible-in-konva-js-to-draw-with-the-left-mouse-button-and-drag-the-stage
  //
  const { observationID, angle, subject, neckUrl, waveCheck } = props
  const isDrawing = React.useRef(false)
  const [draggable, setDraggable] = useState(true)
  const stageRef = useRef(null)
  useFirestoreConnect([
    {
      collection: 'analysisInfo',
      type: 'once',
      where: ['SUBJECT', '==', subject, 'AND', 'ANGLE', '==', angle],
      orderBy: ['date']
    }
  ])
  const accessToken = useSelector(getAccessToken)
  const { figures, hoverKey, hideRegions, ppg, selectedNodes } = useSelector(
    state => state.assessment
  )
  const clinicalAssessments = useSelector(
    state => state.firestore.data.assessments
  )

  const analysisInfo = useSelector(state => state.firestore.data.analysisInfo)

  const { nodeLocations, classificationView, opacity, waveform } = useSelector(
    state => state.controls
  )

  const dispatch = useDispatch()
  // useEffect will run on stageCanvasRef value assignment

  const [image, setImage] = useState(null)
  const [lines, setLines] = useState(null)

  const captureInfo =
    analysisInfo &&
    Object.values(analysisInfo).filter(
      r => r.SUBJECT === String(subject) && r.ANGLE === parseInt(angle)
    )[0]

  const waveformLocation = captureInfo && captureInfo.waveforms[waveform]
  window.captureInfo = captureInfo
  window.subject = subject
  window.angle = angle
  // FIXME this needs to be improved we whiffed on how to present this data, should not be array, should be object.
  const url = `https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/${waveformLocation}`

  useEffect(() => {
    async function getImage () {
      if (!waveform || !waveformLocation) {
        return
      }
      if (accessToken) {
        const image = await fetchImage({ accessToken, url })
        setImage(image)
      }
    }
    getImage()
    return []
  }, [setImage, accessToken, url, captureInfo, waveform, waveformLocation])

  if (!waveformLocation) {
    return null
  }
  const handleMouseUp = e => {
    if (simpleLeftClick(e)) {
      setDraggable(true)
      // TODO: reduce precision and sample rate of figures stored in slice
      dispatch(classifyFigure(lines[0]))
      setLines([])
    }
    isDrawing.current = false
  }
  if (!captureInfo) return null

  const rotation = captureInfo.rotation !== null ? captureInfo.rotation : 5
  const shape = captureInfo.shape !== null ? captureInfo.shape : [152, 232]
  const regions =
    clinicalAssessments && clinicalAssessments[observationID]
      ? clinicalAssessments[observationID].regions
      : []

  const handleMouseDown = e => {
    if (simpleLeftClick(e)) {
      setDraggable(false)
      isDrawing.current = true

      const stage = stageRef.current
      const pos = getRelativePointerPosition(stage)
      setLines([
        {
          nodeClass: getNodeClass(waveCheck),
          points: [pos.x, pos.y],
          key: uuidv4()
        }
      ])
    }
  }

  const handleMouseMove = e => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = getRelativePointerPosition(stage)
    const lastLine = lines[lines.length - 1]
    // add point

    lastLine.points = lastLine.points.concat(point.x, point.y)

    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  function simpleLeftClick (e) {
    return (
      e.evt.button === 0 && !(e.evt.ctrlKey || e.evt.shiftKey || e.evt.altKey)
    )
  }

  const canvasProps = {
    captureInfo,
    image,
    hoverKey,
    lines,
    neckUrl,
    setDraggable,
    regions,
    nodeLocations,
    rotation,
    selectedNodes,
    classificationView,
    figures,
    hideRegions,
    handleMouseMove,
    handleMouseUp,
    handleMouseDown,
    ppg,
    draggable,
    accessToken,
    url,
    opacity,
    angle,
    subject,
    stageRef,
    shape
  }

  return <WaveformCanvas {...canvasProps} />
}

export default WaveformContainer
