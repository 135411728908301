import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Button from '@material-ui/core/Button'
import { useFirestore } from 'react-redux-firebase'
import { useDispatch, useSelector } from 'react-redux'
import {
  hideRegions,
  clearFigures,
  showRegions,
  setSelectedNodes
} from '../redux/assessmentSlice'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { toggleNormalize } from '../redux/controlSlice'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '20ch'
    }
  },
  notes: {
    '& .MuiInputBase-multiline': { width: 'max-content' },

    '& .MuiTextField-root': { width: '30ch' }
  },
  tabRoot: {
    flexGrow: 1,
    minHeight: '30vh',
    backgroundColor: theme.palette.background.paper
  }
}))

function a11yProps (index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  }
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
const RDForm = props => {
  return (
    <>
      <TextField
        id='rd-bpfMin'
        label='BPF Filter Min'
        color='primary'
        value='49'
        margin='dense'
        InputProps={{ readOnly: true }}
      />
      <TextField
        id='rd-bpfMax'
        label='BPF Filter Max'
        color='primary'
        value='150'
        margin='dense'
        InputProps={{ readOnly: true }}
      />
      <Button variant='outlined' color='primary'>
        Update
      </Button>
    </>
  )
}

const CHFForm = props => {
  const classes = useStyles()
  const { subject, angle } = props
  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54')
  )
  const handleDateChange = date => {
    setSelectedDate(date)
  }
  return (
    <>
      <TextField
        className={classes.root}
        id='chf-type'
        label='CHF Type'
        color='primary'
        value='HF_Ref'
        margin='dense'
        InputProps={{ readOnly: true }}
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          readOnly
          disableToolbar
          format='MM/dd/yyyy'
          margin='dense'
          id='date-picker-inline'
          label='Echo Date'
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
        <TextField id='standard-angle' readOnly label='Angle' value={angle} />
        <TextField
          id='standard-subject'
          readOnly
          label='Subject'
          value={subject}
        />
      </MuiPickersUtilsProvider>
    </>
  )
}

const NotesContainer = props => {
  const { measuredHeight, setMeasuredHeight, subject, angle } = props
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const clinician = useSelector(state => state.firebase.auth.email)

  // const {init_note} = useSelector(state=>state.firestore.data)
  const classes = useStyles()
  const assessments = useSelector(state => state.firestore.data.assessments)
  const { figures, selectedNodes } = useSelector(state => state.assessment)
  const { normalize } = useSelector(state => state.controls)
  const [tab, setTab] = useState('1')
  const [note, setNote] = useState(
    assessments && assessments[angle] ? assessments[angle].note : ''
  )

  const pushAssessment = () => {
    const assessment = {
      'capture-id': angle,
      jvpHeight: measuredHeight,
      note,
      created: new Date(),
      modified: new Date(),
      'node-class': selectedNodes,
      regions: figures
    }
    firestore
      .collection(`clinicians/${clinician}/assessments/${subject}/observations`)
      .doc(`${angle}`)
      .set(assessment)
      .then(x => {
        dispatch(clearFigures())
        dispatch(showRegions())
      })
  }

  const clearDrawings = () => {
    dispatch(hideRegions())
    dispatch(clearFigures())
    dispatch(setSelectedNodes([]))
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }
  return (
    <div className={classes.tabRoot}>
      <AppBar position='static'>
        <Tabs
          value={tab}
          onClick={handleChange}
          aria-label='patient information tabs'
          variant='fullWidth'
        >
          <Tab value='1' label='CHF Characteristics' {...a11yProps('2')} />
          <Tab value='2' label='R&D' {...a11yProps('3')} />
        </Tabs>
      </AppBar>
      <form className={classes.root}>
        <TabPanel value={tab} index='1'>
          <CHFForm subject={subject} angle={angle} />
        </TabPanel>
        <TabPanel value={tab} index='2'>
          <RDForm />
        </TabPanel>
        <TextField
          className={classes.notes}
          multiline
          fullwidth='true'
          margin='normal'
          InputLabelProps={{
            shrink: true
          }}
          variant='outlined'
          id='standard-notes'
          label='Notes'
          rows={3}
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        <TextField
          id='jvpHeight'
          label='JVP Height'
          type='number'
          color='primary'
          value={measuredHeight || ''}
          margin='dense'
          inputProps={{ step: 0.1 }}
          onChange={e => setMeasuredHeight(e.target.value)}
        />
      </form>
      <Button variant='outlined' onClick={pushAssessment}>
        Save Assessment
      </Button>
      <Button variant='outlined' onClick={clearDrawings}>
        Clear Regions
      </Button>
      <FormControlLabel
        control={
          <Checkbox
            checked={normalize}
            onChange={() => dispatch(toggleNormalize())}
            name='NormalizeCheckbox'
            color='primary'
          />
        }
        label='Normalize'
      />
    </div>
  )
}

export default NotesContainer
