import React, { useEffect, useState } from 'react'
import SelectorContainer from './SelectorContainer'
import PPGContainer from './PPGContainer'
import NotesContainer from './NotesContainer'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import OptionsContainer from './OptionsContainer'
import FlowSelector from '../components/FlowSelector'
import MeasureContainer from './MeasureContainer'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link } from 'wouter'
import Button from '@material-ui/core/Button'
import WaveformContainer from './WaveformContainer'
import {
  initiateAssessment,
  setMeasureMode,
  setMotionPPG,
  setPpg,
  setPpgMode,
  setSelectedNodes,
  setWaveCheck
} from '../redux/assessmentSlice'
import WaveformControlsContainer from './WaveformControlsContainer'
import WaveSelector from '../components/WaveSelector'
import fetchNode from '../api/fetchNode'
import { getAccessToken } from '../redux/authSelector'
const useStyles = makeStyles(theme => ({
  selectorColumn: {
    minWidth: '480px',
    width: '482px'
  },

  borderedComponent: {
    border: `2px solid ${theme.palette.secondary.main}`,
    margin: '-2px'
  }
}))

const AssessmentContainer = props => {
  const accessToken = useSelector(getAccessToken)
  const { subject, angle } = props
  const clinician = useSelector(state => state.firebase.auth.email)

  useFirestoreConnect([
    {
      collection: 'clinicians',
      doc: clinician,
      subcollections: [{ collection: `/assessments/${subject}/observations` }],
      storeAs: 'assessments',
      type: 'once'
    },
    {
      collection: 'analysisInfo',
      type: 'once',
      where: ['SUBJECT', '==', subject],
      orderBy: ['date']
    }
  ])

  const dispatch = useDispatch()

  const classes = useStyles()
  const [checked, setChecked] = useState({ checked: true })
  const [lines, setLines] = useState([])
  const switchColumnMode = checked => {
    setSelectedRow(0)
    setChecked(checked)
  }
  const clinicalAssessments = useSelector(
    state => state.firestore.data.assessments
  )
  const assessmemt = clinicalAssessments && clinicalAssessments[angle]

  const { ppgMode, measureMode, waveCheck, motionPPG } = useSelector(
    state => state.assessment
  )
  const { waveform } = useSelector(state => state.controls)

  const [selectedRow, setSelectedRow] = useState(0)

  const [measuredHeight, setMeasuredHeight] = useState(
    assessmemt ? assessmemt.jvpHeight : null
  )

  useEffect(() => {
    async function getNode () {
      const ppg = await fetchNode({
        accessToken,
        angle,
        subject,
        variant: waveform.toLowerCase()
      })
      dispatch(setPpg(ppg))
      dispatch(setSelectedNodes(assessmemt ? assessmemt['node-class'] : []))
    }
    dispatch(initiateAssessment())
    getNode()
    return []
  }, [accessToken, angle, subject, dispatch, assessmemt, waveform])

  return (
    <Grid container direction='row' alignItems='stretch'>
      <Grid className={classes.selectorColumn} item xs={3}>
        <Grid
          direction='column'
          justify='flex-start'
          alignItems='stretch'
          container
        >
          <Grid item className={classes.borderedComponent}>
            <FlowSelector
              onOption='Waveform'
              offOption='PPG'
              setCheck={e => dispatch(setPpgMode(e))}
              checkBox={ppgMode}
            />
            {ppgMode.checked &&
              (!measureMode.checked ? (
                <SelectorContainer
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  checked={checked}
                  neckUrl={`https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ref_image_${subject}_${angle}.jpg`}
                />
              ) : (
                <MeasureContainer
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  checked={checked}
                  measuredHeight={measuredHeight}
                  setMeasuredHeight={setMeasuredHeight}
                  angle={angle}
                  subject={subject}
                  neckUrl={`https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ref_image_${subject}_${angle}.jpg`}
                />
              ))}
          </Grid>
          <Grid item>
            >
            <WaveSelector
              waveCheck={waveCheck}
              setWaveCheck={e => dispatch(setWaveCheck(e))}
            />
          </Grid>
          {ppgMode.checked && (
            <Grid item>
              <OptionsContainer
                setCheck={switchColumnMode}
                checked={checked}
                waveCheck={waveCheck}
                setWaveCheck={e => dispatch(setWaveCheck(e))}
                setMeasureMode={e => dispatch(setMeasureMode(e))}
                motion={motionPPG}
                setMotion={e => dispatch(setMotionPPG)}
                measureMode={measureMode}
              />
              {/* placeholder for selector controls */}
            </Grid>
          )}
          <Grid item>
            <NotesContainer
              {...{
                measuredHeight,
                setMeasuredHeight,
                ...props
              }}
            />
          </Grid>
          {!ppgMode.checked && (
            <Grid item>
              <WaveformControlsContainer subject={subject} angle={angle} />
            </Grid>
          )}

          <Grid item>
            <Link to={`/dash/${subject}`}>
              <Button>Return to Dashboard</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.borderedComponent}>
        {ppgMode.checked ? (
          <PPGContainer
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            waveCheck={waveCheck}
            checked={checked}
          />
        ) : (
          <WaveformContainer
            lines={lines}
            setLines={setLines}
            waveCheck={waveCheck}
            observationID={angle}
            neckUrl={`https://jvp-viewer-api.jvplabs.app/v1/images?url=https://storage.cloud.google.com/jvp-labs-secure-images/ref_image_${subject}_${angle}.jpg`}
            angle={angle}
            subject={subject}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default AssessmentContainer
