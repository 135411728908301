import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0d47a1',
      dark: '#003469'
    },
    secondary: {
      main: '#c7c7c7',
      dark: '#9e9e9e'
    },
    accent: {
      main: '#fbc02d',
      dark: '#e5b010'
    },
    background: {
      main: '#eeeeee',
      dark: '#b45f06'
    },
    selectors: {
      first: '#a10d0d',
      second: '#750da1',
      third: '#0d92a1',
      fourth: '#38761d'
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        width: '16ch'
      }
    }
  }
})

export default theme
