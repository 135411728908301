import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { makeStyles } from '@material-ui/core/styles'

const types = ['Biphasic', 'Carotids', 'Unknown', 'Clear']

const defaultButtonStyle = {
  background:
    'linear-gradient(45deg, var(--background-start) 30%, var(--background-end) 90%)',
  borderRadius: 10,
  borderColor: 'black',
  color: 'black',
  height: 48,
  padding: '0 30px',
  boxShadow: '0 3px 5px 2px var(--box-shadow)',
  width: 96
}

const useStyles = makeStyles(theme => {
  return {
    Biphasic: {
      ...defaultButtonStyle
    },
    Carotids: {
      ...defaultButtonStyle
    },
    Unknown: {
      ...defaultButtonStyle
    },
    Trash: {
      ...defaultButtonStyle
    },
    BiphasicClicked: {
      ...defaultButtonStyle,
      background: theme.palette.accent.main
    },
    CarotidsClicked: {
      ...defaultButtonStyle,
      background: theme.palette.selectors.first
    },
    UnknownClicked: {
      ...defaultButtonStyle,
      background: theme.palette.selectors.second,
      focused: {
        background: theme.palette.selectors.second
      }
    },
    TrashClicked: {
      ...defaultButtonStyle,
      background: theme.palette.primary.main
    }
  }
})

function WaveSelector (props) {
  const { waveCheck, setWaveCheck } = props
  const classes = useStyles()

  function handleClick (e) {
    const type = e.currentTarget.value
    const prevClicked = waveCheck[type]
    const nextClicked = !prevClicked

    setWaveCheck({
      ...{ Biphasic: false, Carotids: false, Unknown: false },
      [type]: nextClicked
    })
  }

  return (
    <>
      <ButtonGroup color='primary' aria-label='outlined primary button group'>
        {types.map(type => {
          const className = waveCheck[type]
            ? classes[`${type}Clicked`]
            : classes[type]
          return (
            <Button
              key={type}
              value={type}
              className={className}
              onClick={handleClick}
            >
              {type}
            </Button>
          )
        })}
      </ButtonGroup>
    </>
  )
}

export default WaveSelector
