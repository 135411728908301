import './App.css'
import '../node_modules/react-vis/dist/style.css'

import React from 'react'
import { render } from 'react-dom'
import RouteContainer from './containers/RouteContainer'
import theme from './theme'
import Header from './components/Header'
import Footer from './components/Footer'
import ProviderWrapper from './Provider'

const App = () => {
  return (
    <ProviderWrapper theme={theme}>
      <Header />
      <RouteContainer />
      <Footer />
    </ProviderWrapper>
  )
}

render(<App />, document.getElementById('root'))

export default App
