export const selectedColor = (row, col, nodeClass, selectedNodes, theme) => {
  if (selectedNodes.filter(node => node.x === row && node.y === col).length) {
    if (nodeClass === 'Carotids') {
      return theme.palette.selectors.first
    } else if (nodeClass === 'Unknown') {
      return theme.palette.selectors.second
    }
    return theme.palette.accent.main
  }
  return theme.palette.primary.main
}

export const figureColor = (nodeClass, theme) => {
  switch (nodeClass) {
    case 'Biphasic':
      return theme.palette.accent.main
    case 'Carotids':
      return theme.palette.selectors.first
    case 'Unknown':
      return theme.palette.selectors.second
    default:
      return theme.palette.primary.main
  }
}

export const getNodeClass = nodeClass => {
  switch (true) {
    case nodeClass.Biphasic === true:
      return 'Biphasic'
    case nodeClass.Carotids === true:
      return 'Carotids'
    case nodeClass.Unknown === true:
      return 'Unknown'
    default:
      return 'Error'
  }
}

export function uuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export function getWaveType (state) {
  let nodeClass
  switch (true) {
    case state.waveCheck.Biphasic:
      nodeClass = 'Biphasic'
      break
    case state.waveCheck.Carotids:
      nodeClass = 'Carotids'
      break
    case state.waveCheck.Unknown:
      nodeClass = 'Unknown'
      break
    default:
      nodeClass = 'Error'
  }
  return nodeClass
}
